/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EventType {
    VISIT = 'visit',
    TIME_OFF = 'time_off',
    EXTRA_OFFICE_HOURS = 'ExtraOfficeHours',
    GROUP_VISIT = 'group_visit',
}
