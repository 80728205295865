/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateVisitReservationSchema } from '../models/CreateVisitReservationSchema';
import type { DiagnosticAnswerResponse } from '../models/DiagnosticAnswerResponse';
import type { ReservationCompanySchema } from '../models/ReservationCompanySchema';
import type { ReservationServiceSchema } from '../models/ReservationServiceSchema';
import type { ReservationUserSchema } from '../models/ReservationUserSchema';
import type { ServiceGroupSchema } from '../models/ServiceGroupSchema';
import type { TimeSlotOverviewSchema } from '../models/TimeSlotOverviewSchema';
import type { TimeSlotSchema } from '../models/TimeSlotSchema';
import type { UpdateVisitReservationSchema } from '../models/UpdateVisitReservationSchema';
import type { VisitFormSchema } from '../models/VisitFormSchema';
import type { VisitReservationSchema } from '../models/VisitReservationSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReservationsApi {
    /**
     * Create Visit Reservation
     * Create visit reservation for selected physiotherapeutist and service in given time slots.
     * @returns VisitReservationSchema Created
     * @throws ApiError
     */
    public static reservationsCreateVisitReservationPost({
        requestBody,
    }: {
        requestBody: CreateVisitReservationSchema,
    }): CancelablePromise<VisitReservationSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/reservations/visit-reservation/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Visit Reservation
     * Delete visit reservation.
     * @returns void
     * @throws ApiError
     */
    public static reservationsDeleteVisitReservationDelete({
        visitId,
    }: {
        visitId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/reservations/visit-reservation/',
            query: {
                'visit_id': visitId,
            },
        });
    }
    /**
     * Get Visit Reservation
     * Get visit reservation.
     * @returns VisitReservationSchema OK
     * @throws ApiError
     */
    public static reservationsGetVisitReservationGet({
        visitId,
    }: {
        visitId: string,
    }): CancelablePromise<VisitReservationSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/visit-reservation/',
            query: {
                'visit_id': visitId,
            },
        });
    }
    /**
     * Create Visit Reservation Form
     * Upload document for visit reservation. If not uploaded within 15 minutes from the visit creation, the document will not be accepted.
     * @returns VisitFormSchema Created
     * @throws ApiError
     */
    public static reservationsCreateVisitReservationFormPost({
        visitId,
        formData,
    }: {
        visitId: string,
        formData: {
            file: Blob;
        },
    }): CancelablePromise<VisitFormSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/reservations/visit-reservation/{visit_id}/form/',
            path: {
                'visit_id': visitId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Get Company
     * Get company public information
     * @returns ReservationCompanySchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyGet({
        companyId,
    }: {
        companyId: string,
    }): CancelablePromise<ReservationCompanySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}',
            path: {
                'company_id': companyId,
            },
        });
    }
    /**
     * Get Company Based On Slug
     * Get company public information
     * @returns ReservationCompanySchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyBasedOnSlugGet({
        slug,
    }: {
        slug: string,
    }): CancelablePromise<ReservationCompanySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/slug/{slug}',
            path: {
                'slug': slug,
            },
        });
    }
    /**
     * Get Company Service Groups
     * Get company reservable service groups ordered by service group name.
     * @returns ServiceGroupSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyServiceGroupsGet({
        companyId,
    }: {
        companyId: string,
    }): CancelablePromise<Array<ServiceGroupSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/service-groups/',
            path: {
                'company_id': companyId,
            },
        });
    }
    /**
     * Get Company Services
     * Get company reservable services ordered by service name.
     * @returns ReservationServiceSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyServicesGet({
        companyId,
        serviceGroupId,
    }: {
        companyId: string,
        serviceGroupId?: (string | null),
    }): CancelablePromise<Array<ReservationServiceSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/services/',
            path: {
                'company_id': companyId,
            },
            query: {
                'service_group_id': serviceGroupId,
            },
        });
    }
    /**
     * Get Company Time Slots
     * Get free time slots for selected company, physiotherapists, service and date
     * @returns TimeSlotSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyTimeSlotsGet({
        companyId,
        serviceId,
        date,
        userIds,
        diagnosticId,
        patientNin,
        visitId,
        firstVisitInBatchDateTime,
    }: {
        companyId: string,
        serviceId: string,
        date: string,
        userIds?: (Array<string> | null),
        diagnosticId?: (string | null),
        patientNin?: (string | null),
        visitId?: (string | null),
        firstVisitInBatchDateTime?: (string | null),
    }): CancelablePromise<Array<TimeSlotSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/open-slots/',
            path: {
                'company_id': companyId,
            },
            query: {
                'service_id': serviceId,
                'date': date,
                'user_ids': userIds,
                'diagnostic_id': diagnosticId,
                'patient_nin': patientNin,
                'visit_id': visitId,
                'first_visit_in_batch_date_time': firstVisitInBatchDateTime,
            },
        });
    }
    /**
     * Get Company Time Slots Overview
     * Get free time slots for selected company, physiotherapists, service and date
     * @returns TimeSlotOverviewSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyTimeSlotsOverviewGet({
        companyId,
        serviceId,
        date,
        userIds,
        diagnosticId,
        patientNin,
        visitId,
        firstVisitInBatchDateTime,
    }: {
        companyId: string,
        serviceId: string,
        date: string,
        userIds?: (Array<string> | null),
        diagnosticId?: (string | null),
        patientNin?: (string | null),
        visitId?: (string | null),
        firstVisitInBatchDateTime?: (string | null),
    }): CancelablePromise<Array<TimeSlotOverviewSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/overview/',
            path: {
                'company_id': companyId,
            },
            query: {
                'service_id': serviceId,
                'date': date,
                'user_ids': userIds,
                'diagnostic_id': diagnosticId,
                'patient_nin': patientNin,
                'visit_id': visitId,
                'first_visit_in_batch_date_time': firstVisitInBatchDateTime,
            },
        });
    }
    /**
     * Get Company Users
     * Get company reservable users ordered by last_name, first_name.
     * @returns ReservationUserSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyUsersGet({
        companyId,
        serviceId,
    }: {
        companyId: string,
        serviceId?: (string | null),
    }): CancelablePromise<Array<ReservationUserSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/users/',
            path: {
                'company_id': companyId,
            },
            query: {
                'service_id': serviceId,
            },
        });
    }
    /**
     * Get Diagnostic Answers
     * @returns DiagnosticAnswerResponse OK
     * @throws ApiError
     */
    public static reservationsGetDiagnosticAnswersGet({
        companyId,
        birthNumber,
        newDiagnostic = false,
    }: {
        companyId: string,
        birthNumber: string,
        newDiagnostic?: boolean,
    }): CancelablePromise<Array<DiagnosticAnswerResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/diagnostic-answers/',
            path: {
                'company_id': companyId,
            },
            query: {
                'birth_number': birthNumber,
                'new_diagnostic': newDiagnostic,
            },
        });
    }
    /**
     * Update Visit Reservation
     * Update visit reservation in given time slot.
     * @returns VisitReservationSchema OK
     * @throws ApiError
     */
    public static reservationsUpdateVisitReservationPatch({
        visitId,
        requestBody,
    }: {
        visitId: string,
        requestBody: UpdateVisitReservationSchema,
    }): CancelablePromise<VisitReservationSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/reservations/visit-reservation/{visit_id}/',
            path: {
                'visit_id': visitId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
