/**
 * If phone number is a long string containing country code, this function will parse it into two separate strings.
 * Returns object with phone country code and phone number as two separate properties
 */
export const useParsePhoneNumberByPrefix = (
  fullPhoneNumber: string
): { countryPrefix: string; phoneNumber: string } => {
  const parsedPhoneNumber = {
    countryPrefix: '',
    phoneNumber: '',
  };

  const matchCountryPrefix = fullPhoneNumber.match(/^(\+\d+)\s(.+)$/); // The country code (e.g., "+420")

  if (matchCountryPrefix) {
    parsedPhoneNumber.countryPrefix = matchCountryPrefix[1];
    parsedPhoneNumber.phoneNumber = matchCountryPrefix[2];
  } else {
    parsedPhoneNumber.countryPrefix = '';
    parsedPhoneNumber.phoneNumber = fullPhoneNumber;
  }

  return parsedPhoneNumber;
};
