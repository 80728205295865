/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedPatientUserVisitListSchema } from '../models/PagedPatientUserVisitListSchema';
import type { PatientProfileDetailSchema } from '../models/PatientProfileDetailSchema';
import type { PatientUserDetailSchema } from '../models/PatientUserDetailSchema';
import type { UpdatePatientMe } from '../models/UpdatePatientMe';
import type { UpdatePatientSchema } from '../models/UpdatePatientSchema';
import type { VisitFilterChoices } from '../models/VisitFilterChoices';
import type { VisitFormSchema } from '../models/VisitFormSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PatientPortalApi {
    /**
     * Get Patient User Visit List
     * Get all visits for authenticated PatientUser.
     * @returns PagedPatientUserVisitListSchema OK
     * @throws ApiError
     */
    public static patientPortalGetPatientUserVisitListGet({
        query,
        ordering,
        limit = 100,
        offset,
    }: {
        query?: (VisitFilterChoices | null),
        ordering?: (string | null),
        limit?: number,
        offset?: number,
    }): CancelablePromise<PagedPatientUserVisitListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/patient-portal/',
            query: {
                'query': query,
                'ordering': ordering,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Get Patient User Detail
     * Get authenticated PatientUser detail.
     * @returns PatientUserDetailSchema OK
     * @throws ApiError
     */
    public static patientPortalGetPatientUserDetailGet(): CancelablePromise<PatientUserDetailSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/patient-portal/me',
        });
    }
    /**
     * Put Patient User Detail
     * Change authenticated PatientUser detail.
     * @returns PatientUserDetailSchema Created
     * @throws ApiError
     */
    public static patientPortalPutPatientUserDetailPut({
        requestBody,
    }: {
        requestBody: UpdatePatientMe,
    }): CancelablePromise<PatientUserDetailSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/patient-portal/me',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Profile Picture
     * Upload my patient profile picture.
     * @returns PatientUserDetailSchema Created
     * @throws ApiError
     */
    public static patientPortalUpdateProfilePicturePost({
        formData,
    }: {
        formData: {
            image: Blob;
        },
    }): CancelablePromise<PatientUserDetailSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/patient-portal/me/profile-picture/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Delete Profile Picture
     * Delete my patient profile picture.
     * @returns void
     * @throws ApiError
     */
    public static patientPortalDeleteProfilePictureDelete(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/patient-portal/me/profile-picture/',
        });
    }
    /**
     * Get Patient List
     * Get list of patients for patient user.
     * @returns PatientProfileDetailSchema OK
     * @throws ApiError
     */
    public static patientPortalGetPatientListGet(): CancelablePromise<Array<PatientProfileDetailSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/patient-portal/me/patients/',
        });
    }
    /**
     * Upload Patient Document
     * Upload document for patient.
     * @returns VisitFormSchema Created
     * @throws ApiError
     */
    public static patientPortalUploadPatientDocumentPost({
        patientId,
        formData,
    }: {
        patientId: string,
        formData: {
            file: Blob;
        },
    }): CancelablePromise<VisitFormSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/patient-portal/me/patients/{patient_id}/document',
            path: {
                'patient_id': patientId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Update Patient
     * Update patient.
     * @returns PatientProfileDetailSchema OK
     * @throws ApiError
     */
    public static patientPortalUpdatePatientPut({
        patientId,
        requestBody,
    }: {
        patientId: string,
        requestBody: UpdatePatientSchema,
    }): CancelablePromise<PatientProfileDetailSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/patient-portal/me/patients/{patient_id}/',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
