import { UserState } from './useUser';
import { PermissionsCodename } from '../../api';

export const userPermissionGetters = {
  canInviteColleagues: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.INVITE_COLLEAGUES) ??
      false
    );
  },
  canUpdateColleague: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.UPDATE_COLLEAGUE) ??
      false
    );
  },
  canDeactivateColleague: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.DEACTIVATE_COLLEAGUE
      ) ?? false
    );
  },
  canGetColleagueDetail: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.GET_COLLEAGUE) ??
      false
    );
  },
  canUpdateColleagueWorkingHours: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.UPDATE_COLLEAGUE_WORKING_HOURS
      ) ?? false
    );
  },
  canGetColleagueWorkingHoursDetail: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.GET_COLLEAGUE_WORKING_HOURS
      ) ?? false
    );
  },
  canUpdateColleagueRole: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.UPDATE_COLLEAGUE_ROLE
      ) ?? false
    );
  },
  canEditCompanyDetails: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.EDIT_COMPANY_DETAILS
      ) ?? false
    );
  },
  canCreateOrder: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.CREATE_ORDER) ??
      false
    );
  },
  canPayOrder: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.PAY_ORDER) ?? false
    );
  },
  canCreatePatient: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.CREATE_PATIENT) ??
      false
    );
  },
  canUpdatePatient: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.UPDATE_PATIENT) ??
      false
    );
  },
  canRemovePatient: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.REMOVE_PATIENT) ??
      false
    );
  },
  canCreatePatientTicket: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.CREATE_PATIENT_TICKET
      ) ?? false
    );
  },
  canMergePatients: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.MERGE_PATIENTS) ??
      false
    );
  },
  canCreateService: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.CREATE_SERVICE) ??
      false
    );
  },
  canUpdateService: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.UPDATE_SERVICE) ??
      false
    );
  },
  canDeleteService: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.REMOVE_SERVICE) ??
      false
    );
  },
  canCreateTicket: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.CREATE_TICKET) ??
      false
    );
  },
  canUpdateTicket: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.UPDATE_TICKET) ??
      false
    );
  },
  canGetTicketDetail: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.GET_TICKET_DETAIL) ??
      false
    );
  },
  canGetServiceDetail: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.GET_SERVICE_DETAIL
      ) ?? false
    );
  },
  canCreateColleagueTimeOff: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.CREATE_COLLEAGUE_TIME_OFF
      ) ?? false
    );
  },
  canUpdateColleagueTimeOff: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.UPDATE_COLLEAGUE_TIME_OFF
      ) ?? false
    );
  },
  canDeleteColleagueTimeOff: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.DELETE_COLLEAGUE_TIME_OFF
      ) ?? false
    );
  },
  canGetSubscription: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.GET_SUBSCRIPTION) ??
      false
    );
  },
  canCreateSubscription: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.CREATE_SUBSCRIPTION
      ) ?? false
    );
  },
  canDeleteSubscription: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.REMOVE_SUBSCRIPTION
      ) ?? false
    );
  },
  canUpdateSubscription: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.UPDATE_SUBSCRIPTION
      ) ?? false
    );
  },
  canCreateSubscriptionPayment: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.CREATE_SUBSCRIPTION_PAYMENT
      ) ?? false
    );
  },
  canUpdateSubscriptionPayment: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.UPDATE_SUBSCRIPTION_PAYMENT
      ) ?? false
    );
  },
  canViewColleagueVisit: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.GET_COLLEAGUE_VISIT_DETAIL
      ) ?? false
    );
  },
  canCreateRoom: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.CREATE_ROOM) ?? false
    );
  },
  canUpdateRoom: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.UPDATE_ROOM) ?? false
    );
  },
  canExportPatients: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.EXPORT_PATIENTS) ??
      false
    );
  },
  canCreateReceipt: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.CREATE_SELF_RECEIPT
      ) ?? false
    );
  },
  canUpdateReceipt: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.UPDATE_SELF_RECEIPT
      ) ?? false
    );
  },
  canCreateVisit: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.CREATE_VISIT) ??
      false
    );
  },
  canGetVisitDetail: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.GET_VISIT_DETAIL) ??
      false
    );
  },
  canUpdateVisit: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.UPDATE_VISIT) ??
      false
    );
  },
  canCreateGroupVisit: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.CREATE_GROUP_VISIT
      ) ?? false
    );
  },
  canUpdateGroupVisit: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.UPDATE_GROUP_VISIT
      ) ?? false
    );
  },
  canGetGroupVisitDetail: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.GET_GROUP_VISIT_DETAIL
      ) ?? false
    );
  },
  canRemoveGroupVisit: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.REMOVE_GROUP_VISIT
      ) ?? false
    );
  },
  canDeleteVisitReview: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.DELETE_VISIT_REVIEW
      ) ?? false
    );
  },
  canViewCalendarUsers: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.VIEW_CALENDAR_USERS_SUBTAB
      ) ?? false
    );
  },
  canViewCalendarRooms: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.VIEW_CALENDAR_ROOMS_SUBTAB
      ) ?? false
    );
  },
  canViewCalendarPlanning: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.VIEW_CALENDAR_PLANNING_SUBTAB
      ) ?? false
    );
  },
  canViewCalendarRoomsPlanning: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.VIEW_CALENDAR_ROOMS_PLANNING_SUBTAB
      ) ?? false
    );
  },
  canViewMenuSubTabOfficeExercises: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(
        PermissionsCodename.VIEW_OFFICE_EXERCISES_SUBTAB
      ) ?? false
    );
  },
  canViewInteractiveGuide: (state: UserState): boolean => {
    return (
      state.user?.permissions.includes(PermissionsCodename.GET_GUIDE_DATA) ??
      false
    );
  },
};
