import { VisitFilterChoices } from 'src/api';

export interface SelectOption {
  id: string;
  name: string;
}

export interface KBatchDocumentListFilterSchema {
  search?: string;
}

export interface TimePickerConfig {
  keepActionRow?: boolean;
  shadowDom?: boolean;
}

export interface BillingData {
  companyId?: string;
  name?: string;
  companyVatId?: string;
  bankAccountNumber?: string;
  bankCode?: string;
  iban?: string;
  city?: string;
  zipCode?: string;
  street?: string;
  invoiceFooter?: string;
}
export enum DrawingType {
  Blank = 'blank',
  Front = 'front',
  Side = 'side',
}

interface ChooseStrategyDialogConfigItem<TType> {
  label: string;
  value: TType;
}

export interface ChooseStrategyDialogConfig<TType> {
  message?: string;
  okText?: string;
  cancelText?: string;
  items: ChooseStrategyDialogConfigItem<TType>[];
  destructive?: boolean;
}

export interface PatientUserVisitListGetRequestParams {
  query: VisitFilterChoices;
  ordering: string;
  limit: number;
  offset: number;
}

export enum ApplicationTheme {
  FYZION = 'fyzion',
  CORTEX = 'cortex',
}
