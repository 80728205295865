import { defineStore } from 'pinia';
import {
  type PasswordChange,
  type Me,
  type UpdateMe,
  UsersApi,
  AuthApi,
  GoogleAuthApi,
  GoogleCalendarSyncSchema,
  PermissionsCodename,
  RefreshResponse,
  CompanyPermissionsCodename,
  PatientPortalApi,
  PatientAuthApi,
  PatientUserDetailSchema,
  UpdatePatientMe,
  PatientProfileDetailSchema,
  type UpdatePatientSchema,
} from 'src/api';
import type { Errors } from 'src/utils/apiErrors';
import { Cookies, Notify } from 'quasar';
import gettext from '../../utils/gettext';
// polyfill for the Locks API (not available on Safari <15.3)
// uses localStorage to emulate locking
import 'navigator.locks';
import { userPermissionGetters } from './useUserPermisionsGetters';

const { $gettext } = gettext;

export interface UserState {
  user: Me | null;
  access_token: string | null;
  is_logged_as_patient_user: string | null;
  errors: Errors;
  google_auth_url: string | null;
  patientUser: PatientUserDetailSchema | null;
  patientUserProfiles: PatientProfileDetailSchema[] | null;
  currentFacilitySlug: string | string[] | null;
}

export const useUser = defineStore({
  id: 'useUser',
  state: () => {
    return {
      user: null,
      access_token: window.localStorage.getItem('accessToken'),
      is_logged_as_patient_user: window.localStorage.getItem('isPatientUser'),
      errors: {},
      google_auth_url: null,
      patientUser: null,
      patientUserProfiles: null,
      currentFacilitySlug: null,
    } as UserState;
  },
  getters: {
    ...userPermissionGetters,
    loggedIn: (state): boolean => !!state.access_token,
    loggedAsPatientUser: (state): boolean => {
      return String(state.is_logged_as_patient_user) === 'true';
    },
    handleCompanyHasPermission:
      (state) =>
      (permissionCodename: CompanyPermissionsCodename): boolean => {
        return (
          state.user?.company_permissions.some((companyPermission) => {
            return (
              companyPermission.permission === permissionCodename &&
              companyPermission.company_has_permission
            );
          }) ?? false
        );
      },
    handleUserHasPermission:
      (state) =>
      (permissionCodename: PermissionsCodename): boolean => {
        return (
          state.user?.permissions.some((permission) => {
            return permission === permissionCodename;
          }) ?? false
        );
      },
    getCurrentFacilitySlug(): string | string[] | null {
      return this.currentFacilitySlug;
    },
    getPreferredLanguage(): string {
      return Cookies.get('preferredLanguage') || navigator.language;
    },
  },
  actions: {
    setToken(access: string, refresh: string, isPatientUser?: boolean) {
      this.access_token = access;
      window.localStorage.setItem('accessToken', access);
      window.localStorage.setItem('refreshToken', refresh);
      window.localStorage.setItem(
        'isPatientUser',
        isPatientUser ? 'true' : 'false'
      );
    },
    async signIn(
      email: string,
      password: string,
      companyId?: string,
      isPatientUser?: boolean
    ) {
      await AuthApi.authSignInPost({
        requestBody: {
          email: email,
          password: password,
          company_id: companyId,
        },
      })
        .then(async (response) => {
          this.errors = {};
          await navigator.locks.request('refreshTokenLock', () => {
            this.setToken(response.access, response.refresh, isPatientUser);
          });
        })
        .catchApiErrors((errors) => (this.errors = errors));
    },
    async patientSignUp(
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      phone: string,
      companyId: string
    ) {
      await PatientAuthApi.patientAuthCreatePatientUserPost({
        requestBody: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          phone: phone,
          street: null,
          city: null,
          zipCode: null,
          companyId: companyId,
        },
      })
        .then(() => {
          this.errors = {};
          Notify.create({
            message: $gettext(
              'Registrace proběhla úspěšně. Prosím potvrďte ještě svojí emailovou adresu kliknutím na odkaz ve vašem emailu!'
            ),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 5000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Registrace se nepovedla'),
            color: 'negative',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        });
    },
    async signUp(
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      phone: string,
      sourceOfInformation: string,
      optedForInsurance: boolean
    ) {
      await AuthApi.authSignUpPost({
        requestBody: {
          email: email,
          password: password,
          phone: phone,
          firstName: firstName,
          lastName: lastName,
          sourceOfInformation:
            sourceOfInformation == '' ? undefined : sourceOfInformation,
          optedForInsurance: optedForInsurance,
        },
      })
        .then(() => {
          this.errors = {};
          Notify.create({
            message: $gettext('Registrace proběhla úspěšně'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Registrace se nepovedla'),
            color: 'negative',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        });
    },
    async refresh() {
      // save the old token before acquiring the lock
      const preLockToken = window.localStorage.getItem('refreshToken');
      await navigator.locks.request('refreshTokenLock', async () => {
        const token = window.localStorage.getItem('refreshToken');
        if (!token) {
          throw new Error('No refresh token');
        }
        // if the token is different, it means that it has already been refreshed from elsewhere
        if (token != preLockToken) {
          this.access_token = localStorage.getItem('accessToken');
          return;
        }
        await AuthApi.authRefreshPost({
          requestBody: { refresh: token },
        }).then((r: RefreshResponse) => {
          this.setToken(r.access, r.refresh);
        });
      });
    },
    async logout() {
      let refreshToken: string | null = null;
      await navigator.locks.request('refreshTokenLock', () => {
        refreshToken = window.localStorage.getItem('refreshToken');
        this.access_token = null;
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('isPatientUser');
      });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (refreshToken) {
        await AuthApi.authLogoutDelete({
          requestBody: {
            refresh: refreshToken,
          },
        });
      }
    },
    async getUser() {
      await UsersApi.usersGetMeGet().then((r) => {
        this.user = r;
      });
    },
    async getPatientUser() {
      await PatientPortalApi.patientPortalGetPatientUserDetailGet().then(
        (r) => {
          this.patientUser = r;
        }
      );
    },
    async updatePatientUserDetail(updatePatientUserObj: UpdatePatientMe) {
      await PatientPortalApi.patientPortalPutPatientUserDetailPut({
        requestBody: updatePatientUserObj,
      })
        .then((response) => {
          this.errors = {};
          this.patientUser = response;

          Notify.create({
            message: $gettext('Údaje úspěšně změněny'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 2000,
          });
        })
        .catchApiErrors((err: Errors) => {
          this.errors = err;
          Notify.create({
            message: $gettext(
              'Změna se nezdařila. Prosím zkontrolujte údaje ve formuláři'
            ),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 2000,
          });
        });
    },
    async postPatientUserProfilePhoto(image: Blob) {
      await PatientPortalApi.patientPortalUpdateProfilePicturePost({
        formData: { image },
      })
        .then((response) => {
          this.patientUser = response;
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Nahrání obrázku se nezdařilo'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async deletePatientUserProfilePhoto() {
      await PatientPortalApi.patientPortalDeleteProfilePictureDelete()
        .then(() => {
          if (this.patientUser?.profile_picture) {
            this.patientUser.profile_picture.file_name = '';
            this.patientUser.profile_picture.thumbnail_300x300 = '';
            this.patientUser.profile_picture.thumbnail_150x150 = '';
            this.patientUser.profile_picture.image = '';
            this.patientUser.profile_picture.id = '';
          }
          Notify.create({
            message: $gettext('Obrázek byl smazán'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Smazání obrázku se nezdařilo'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async getPatientUserProfiles() {
      await PatientPortalApi.patientPortalGetPatientListGet()
        .then((response) => {
          this.patientUserProfiles = response;
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Smazání obrázku se nezdařilo'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async uploadPatientUserForm(patientId: string, file: Blob) {
      await PatientPortalApi.patientPortalUploadPatientDocumentPost({
        patientId: patientId,
        formData: { file: file },
      })
        .then(() => {
          Notify.create({
            message: $gettext('Dokument byl úspěšně nahrán a odeslán'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 10000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
            });
          });
        });
    },
    async putPatientUserUpdatePatientProfile(
      patientId: string,
      requestBody: UpdatePatientSchema
    ) {
      await PatientPortalApi.patientPortalUpdatePatientPut({
        patientId,
        requestBody,
      })
        .then(() => {
          Notify.create({
            message: $gettext('Uloženo'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((errors) => (this.errors = errors));
      return true;
    },
    async updateUser(data: UpdateMe) {
      await UsersApi.usersUpdateMePut({
        requestBody: data,
      })
        .then((r) => {
          this.user = r;
          Notify.create({
            message: $gettext('Uloženo'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((errors) => (this.errors = errors));
      return true;
    },
    async changePassword(data: PasswordChange) {
      await UsersApi.usersPasswordChangePost({
        requestBody: data,
      })
        .then(() => {
          Notify.create({
            message: $gettext('Heslo bylo úspěšně změněno'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
              timeout: 1000,
            });
          });
        });
      return true;
    },
    async uploadProfilePicture(image: File) {
      await UsersApi.usersUpdateProfilePicturePost({
        formData: { image: image },
      })
        .then((r) => {
          this.user = r;
          Notify.create({
            message: $gettext('Profilový obrázek byl úspěšně nahrán'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
              timeout: 1000,
            });
          });
        });
    },
    async getGoogleAuthUrl() {
      if (!this.user?.id) return;

      const resolveRedirectUrl = (): string => {
        const path = this.router.resolve({ name: 'calendar-sync' }).href;
        return window.location.origin.concat(path);
      };

      const requestParams = {
        customerUserId: this.user.id,
        redirectUrl: resolveRedirectUrl(),
      };
      await GoogleAuthApi.googleAuthGetAuthUrlGet(requestParams).then((r) => {
        this.google_auth_url = r.authorization_url;
      });
    },
    async syncGoogleCalendar(data: GoogleCalendarSyncSchema) {
      await GoogleAuthApi.googleAuthSyncGoogleCalendarPost({
        requestBody: data,
      })
        .then(() => {
          if (this.user) this.user.synced_google_calendar = true;
          Notify.create({
            message: $gettext('Kalendář byl úspěšně synchronizován'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Synchronizace se nezdařila'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async revokeGoogleCalendarSync() {
      await GoogleAuthApi.googleAuthRevokeGoogleAccessDelete({
        customerUserId: this.user?.id ?? '',
      })
        .then(() => {
          if (this.user) this.user.synced_google_calendar = false;
          Notify.create({
            message: $gettext('Synchronizace kalendáře byla zrušena'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Synchronizace se nezdařila'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async handleRedirectToSubscriptionPage() {
      await this.router.push({ name: 'subscription-plan' });
    },
    handleServerError() {
      const { $gettext } = gettext;
      Notify.create({
        message: $gettext(
          'Neočekávaná chyba. Stránka se znovu načte za 10 sekund.'
        ),
        color: 'negative',
        icon: 'fa-solid fa-circle-check',
        timeout: 10000,
      });
      const resetRouter = (): void => {
        this.router.go(0);
      };
      setInterval(resetRouter, 10000);
    },
    setCurrentFacilitySlug(companyId: string | string[] | null) {
      this.currentFacilitySlug = companyId;
    },
  },
});
