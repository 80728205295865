import {
  ReceiptPatientPaymentType,
  ReceiptPaymentType,
  TimeOffType,
} from 'src/api';
import { receiptPatientPaymentTypeOptionsMapper } from 'src/utils/mappers';
import gettext from '../utils/gettext';
const { $gettext } = gettext;

export enum RichTextEditTemplateApplyType {
  ADD = 'add',
  REPLACE = 'replace',
}

export interface PaymentOption {
  value: ReceiptPatientPaymentType | ReceiptPaymentType;
  label: string;
}

export const receiptPatientPaymentTypeOptions: PaymentOption[] = [
  {
    label: receiptPatientPaymentTypeOptionsMapper(
      ReceiptPatientPaymentType.CASH
    ),
    value: ReceiptPatientPaymentType.CASH,
  },
  {
    label: receiptPatientPaymentTypeOptionsMapper(
      ReceiptPatientPaymentType.BANK_TRANSFER
    ),
    value: ReceiptPatientPaymentType.BANK_TRANSFER,
  },
  {
    label: receiptPatientPaymentTypeOptionsMapper(
      ReceiptPatientPaymentType.CARD
    ),
    value: ReceiptPatientPaymentType.CARD,
  },
];

export const onlineReservationPaymentTypeOptions: PaymentOption[] = [
  {
    label: $gettext('V ordinaci hotově/kartou'),
    value: ReceiptPatientPaymentType.CASH,
  },
  {
    label: $gettext('Kartou online'),
    value: ReceiptPatientPaymentType.CARD,
  },
];

export const timeOffTypeOptions = [
  { value: TimeOffType.TIME_OFF, label: $gettext('Volno (Dovolená)') },
  {
    value: TimeOffType.EXTRA_OFFICE_HOURS,
    label: $gettext('Nepravidelné ordinační hodiny'),
  },
];

export enum PaymentStatusOptions {
  PAID = 'PAID',
  REJECTED = 'PENDING',
}

export const phonePrefixOptions = [
  '+1', // USA, Kanada
  '+20', // Egypt
  '+211', // Jižní Súdán
  '+212', // Maroko
  '+213', // Alžírsko
  '+216', // Tunisko
  '+218', // Libye
  '+220', // Gambie
  '+221', // Senegal
  '+222', // Mauritánie
  '+223', // Mali
  '+224', // Guinea
  '+225', // Pobřeží slonoviny
  '+226', // Burkina Faso
  '+227', // Niger
  '+228', // Togo
  '+229', // Benin
  '+230', // Mauricius
  '+231', // Libérie
  '+232', // Sierra Leone
  '+233', // Ghana
  '+234', // Nigérie
  '+235', // Čad
  '+236', // Středoafrická republika
  '+237', // Kamerun
  '+238', // Kapverdy
  '+239', // Svatý Tomáš a Princův ostrov
  '+240', // Rovníková Guinea
  '+241', // Gabon
  '+242', // Kongo (Brazzaville)
  '+243', // Kongo (Kinshasa)
  '+244', // Angola
  '+245', // Guinea-Bissau
  '+246', // Diego Garcia
  '+247', // Ascension
  '+248', // Seychely
  '+249', // Súdán
  '+250', // Rwanda
  '+251', // Etiopie
  '+252', // Somálsko
  '+253', // Džibutsko
  '+254', // Keňa
  '+255', // Tanzanie
  '+256', // Uganda
  '+257', // Burundi
  '+258', // Mosambik
  '+260', // Zambie
  '+261', // Madagaskar
  '+262', // Réunion
  '+263', // Zimbabwe
  '+264', // Namibie
  '+265', // Malawi
  '+266', // Lesotho
  '+267', // Botswana
  '+268', // Svazijsko
  '+269', // Komory
  '+27', // Jižní Afrika
  '+290', // Svatá Helena
  '+291', // Eritrea
  '+297', // Aruba
  '+298', // Faerské ostrovy
  '+299', // Grónsko
  '+30', // Řecko
  '+31', // Nizozemsko
  '+32', // Belgie
  '+33', // Francie
  '+34', // Španělsko
  '+350', // Gibraltar
  '+351', // Portugalsko
  '+352', // Lucembursko
  '+353', // Irsko
  '+354', // Island
  '+355', // Albánie
  '+356', // Malta
  '+357', // Kypr
  '+358', // Finsko
  '+359', // Bulharsko
  '+36', // Maďarsko
  '+370', // Litva
  '+371', // Lotyšsko
  '+372', // Estonsko
  '+373', // Moldavsko
  '+374', // Arménie
  '+375', // Bělorusko
  '+376', // Andorra
  '+377', // Monako
  '+378', // San Marino
  '+379', // Vatikán
  '+380', // Ukrajina
  '+381', // Srbsko
  '+382', // Černá Hora
  '+383', // Kosovo
  '+385', // Chorvatsko
  '+386', // Slovinsko
  '+387', // Bosna a Hercegovina
  '+389', // Severní Makedonie
  '+39', // Itálie
  '+40', // Rumunsko
  '+41', // Švýcarsko
  '+420', // Česko
  '+421', // Slovensko
  '+423', // Lichtenštejnsko
  '+43', // Rakousko
  '+44', // Spojené království
  '+45', // Dánsko
  '+46', // Švédsko
  '+47', // Norsko
  '+48', // Polsko
  '+49', // Německo
  '+500', // Falklandy
  '+501', // Belize
  '+502', // Guatemala
  '+503', // Salvador
  '+504', // Honduras
  '+505', // Nikaragua
  '+506', // Kostarika
  '+507', // Panama
  '+508', // Svatý Pierre a Miquelon
  '+509', // Haiti
  '+51', // Peru
  '+52', // Mexiko
  '+53', // Kuba
  '+54', // Argentina
  '+55', // Brazílie
  '+56', // Chile
  '+57', // Kolumbie
  '+58', // Venezuela
  '+590', // Guadeloupe
  '+591', // Bolívie
  '+592', // Guyana
  '+593', // Ekvádor
  '+594', // Francouzská Guyana
  '+595', // Paraguay
  '+596', // Martinik
  '+597', // Surinam
  '+598', // Uruguay
  '+599', // Nizozemské Antily
  '+60', // Malajsie
  '+61', // Austrálie
  '+62', // Indonésie
  '+63', // Filipíny
  '+64', // Nový Zéland
  '+65', // Singapur
  '+66', // Thajsko
  '+670', // Východní Timor
  '+672', // Australská antarktická území
  '+673', // Brunej
  '+674', // Nauru
  '+675', // Papua-Nová Guinea
  '+676', // Tonga
  '+677', // Šalomounovy ostrovy
  '+678', // Vanuatu
  '+679', // Fidži
  '+680', // Palau
  '+681', // Wallis a Futuna
  '+682', // Cookovy ostrovy
  '+683', // Niue
  '+685', // Samoa
  '+686', // Kiribati
  '+687', // Nová Kaledonie
  '+688', // Tuvalu
  '+689', // Francouzská Polynésie
  '+690', // Tokelau
  '+691', // Mikronésie
  '+692', // Marshallovy ostrovy
  '+7', // Rusko, Kazachstán
  '+81', // Japonsko
  '+82', // Jižní Korea
  '+84', // Vietnam
  '+850', // Severní Korea
  '+852', // Hongkong
  '+853', // Macao
  '+855', // Kambodža
  '+856', // Laos
  '+86', // Čína
  '+880', // Bangladéš
  '+886', // Tchaj-wan
  '+90', // Turecko
  '+91', // Indie
  '+92', // Pákistán
  '+93', // Afghánistán
  '+94', // Srí Lanka
  '+95', // Myanmar
  '+960', // Maledivy
  '+961', // Libanon
  '+962', // Jordánsko
  '+963', // Sýrie
  '+964', // Irák
  '+965', // Kuvajt
  '+966', // Saúdská Arábie
  '+967', // Jemen
  '+968', // Omán
  '+970', // Palestina
  '+971', // Spojené arabské emiráty
  '+972', // Izrael
  '+973', // Bahrajn
  '+974', // Katar
  '+975', // Bhútán
  '+976', // Mongolsko
  '+977', // Nepál
  '+98', // Írán
  '+992', // Tádžikistán
  '+993', // Turkmenistán
  '+994', // Ázerbájdžán
  '+995', // Gruzie
  '+996', // Kyrgyzstán
  '+998', // Uzbekistán
];
