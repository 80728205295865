import { defineStore } from 'pinia';
import { CustomerUserGuideDataSchema, UsersApi } from 'src/api';
import { Notify } from 'quasar';
import type { Errors } from '../../utils/apiErrors';

export interface InteractiveGuideState {
  guideCurrentlyRunning: boolean;
  guideProgress: CustomerUserGuideDataSchema;
  expandedItems: Record<string, boolean>;
  errors: Errors;
}

export const useInteractiveGuide = defineStore('useInteractiveGuide', {
  state: (): InteractiveGuideState => {
    return {
      guideCurrentlyRunning: false,
      guideProgress: {
        dismissed: false,
        dismissed_times: 0,
        introduction_steps_completed: 0,
        basic_steps_completed: 0,
        advanced_steps_completed: 0,
      },
      expandedItems: {},
      errors: {},
    };
  },
  actions: {
    setGuideActivityState(newState: boolean) {
      this.guideCurrentlyRunning = newState;
    },
    setGuideExpandedItems(newState: Record<string, boolean>) {
      this.expandedItems = newState;
    },
    async getGuideDataSet() {
      await UsersApi.usersGetGuideDataGet()
        .then((r) => {
          this.guideProgress = r;
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
              timeout: 2000,
            });
          });
        });
    },
    async putGuideProgress() {
      await UsersApi.usersUpdateGuideDataPut({
        requestBody: this.guideProgress,
      })
        .then(() => {})
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
              timeout: 2000,
            });
          });
        });
    },
  },
});
