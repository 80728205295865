/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiagnosticSchema } from '../models/DiagnosticSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DiagnosticsApi {
    /**
     * Get Company Diagnostics
     * Get list of diagnostics for selection. <br>Required Permission: 'get_company_diagnostics_list'
     * @returns DiagnosticSchema OK
     * @throws ApiError
     */
    public static diagnosticsGetCompanyDiagnosticsGet(): CancelablePromise<Array<DiagnosticSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/diagnostics/',
        });
    }
}
