<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
  title?: string;
  mobileOnlyTitle?: boolean;
  calendar?: boolean;
  showBorder?: boolean;
  align?: 'row' | 'col';
  headingAlign?: 'start' | 'center';
  actionsAlign?: 'start' | 'center' | 'end';
  singleBtn?: boolean;
  headingLevel?: 'h1' | 'h2';
  bold?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  mobileOnlyTitle: false,
  calendar: false,
  showBorder: false,
  align: 'col',
  headingAlign: 'start',
  actionsAlign: 'center',
  singleBtn: false,
  headingLevel: 'h1',
  bold: true,
});

const titleClasses = computed(() => {
  return [
    props.mobileOnlyTitle ? 'header__title--mobile-only' : null,
    !props.showBorder || props.align === 'row' ? null : 'header__title--border',
    props.headingAlign === 'center' ? 'justify-center' : '',
    props.bold ? 'bold' : '',
  ];
});

const rightActionsClasses = computed(() => {
  return [
    props.singleBtn ? 'header__right--single-btn' : null,
    props.calendar ? 'header__right--calendar' : '',
  ];
});
</script>

<template>
  <div class="header" :class="align === 'row' ? 'header--row' : 'header--col'">
    <div v-if="title" class="header__title" :class="titleClasses">
      <h1 v-if="headingLevel === 'h1'">
        {{ title }}
      </h1>

      <h2 v-else>
        {{ title }}
      </h2>
    </div>

    <div
      v-if="$slots.left || $slots.right"
      class="header__actions"
      :class="`header__actions--align-${actionsAlign}`"
    >
      <div
        v-if="$slots.left"
        class="header__left"
        :class="calendar ? 'header__left--calendar' : ''"
      >
        <slot name="left"></slot>
      </div>

      <div
        v-if="$slots.right"
        class="header__right"
        :class="rightActionsClasses"
      >
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// can't separate this css into its own file because of using :slotted - it doesn't work when it is in its own file

.header {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  flex-wrap: nowrap;
  margin-bottom: 2.5rem;

  &--row {
    flex-direction: row;
  }

  &--col {
    flex-direction: column;
  }

  &__title {
    display: flex;
    align-items: center;

    &--border {
      width: 100%;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba($color: $dark-300, $alpha: 0.3);
    }

    &--mobile-only {
      @media (min-width: 920px) {
        display: none;
      }
    }

    h1,
    h2 {
      font-weight: 300;
      white-space: nowrap;
      font-size: 2rem;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;

    &--align-start {
      align-items: flex-start;
    }

    &--align-center {
      align-items: center;
    }

    &--align-end {
      align-items: flex-end;
    }
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }

  &__left {
    justify-content: flex-start;
    flex-wrap: nowrap;

    :deep(.q-btn) {
      height: 4.4rem;
    }

    :slotted(h2) {
      font-size: 2rem;
      font-weight: 700;
      white-space: nowrap;
    }

    &--calendar {
      width: 100%;

      @media (min-width: 500px) {
        width: fit-content;
      }
    }
  }

  &__right {
    justify-content: flex-end;

    &--calendar {
      width: fit-content;

      @media (min-width: 500px) {
        width: 100%;
      }
    }

    &--single-btn {
      @media (max-width: 560px) {
        :slotted(.q-btn) {
          height: 4.4rem;

          i.q-icon {
            margin: 0;
          }

          span.block {
            display: none !important;
          }
        }
      }
    }
  }
}

.bold {
  h1,
  h2 {
    font-weight: 700 !important;
  }
}
</style>
