/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ColorChoices {
    MODRA = 'modra',
    ZLUTA = 'zluta',
    ZELENA = 'zelena',
    CERVENA = 'cervena',
}
