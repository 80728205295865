/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePatientUserSchema } from '../models/CreatePatientUserSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PatientAuthApi {
    /**
     * Create Patient User
     * Create Patient User
     * @returns any OK
     * @throws ApiError
     */
    public static patientAuthCreatePatientUserPost({
        requestBody,
    }: {
        requestBody: CreatePatientUserSchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/patient-auth/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
