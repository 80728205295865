import { ColCellContentArg, ColHeaderContentArg } from '@fullcalendar/resource';
import gettext from 'src/utils/gettext';
import { AllCalendarViewType } from '../_composables/useMultiSelectCalendar';
import { useUser } from 'stores/user/useUser';
import { EventType, UserEventSchema } from '../../api';
import { useRouter } from 'vue-router';

const { $gettext } = gettext;

export enum CalendarViewType {
  Day = 'resourceTimeGridDay',
  Week = 'resourceTimeGridWeek',
  WorkingDays = 'resourceTimeGridWorkingDays',
  ThreeDays = 'resourceTimeGridThreeDays',
  Month = 'resourceDayGridMonth',
}

export enum ResourceCalendarViewType {
  Day = 'resourceTimeGridDay',
  Week = 'resourceTimeGridWeek',
  WorkingDays = 'resourceTimeGridWorkingDays',
  ThreeDays = 'resourceTimeGridThreeDays',
}

export enum PlanningCalendarViewType {
  Day = 'resourceTimelineDay',
  Week = 'resourceTimelineWeek',
}

export enum CalendarRoute {
  Users = 'calendar-users',
  Rooms = 'calendar-rooms',
  Planning = 'calendar-planning',
  RoomPlanning = 'calendar-room-planning',
}

export const canViewDetailByTypeAndPermission = (
  event: UserEventSchema
): boolean => {
  const userStore = useUser();

  if (event.extendedProps.event_type === EventType.GROUP_VISIT) {
    return userStore.canGetGroupVisitDetail;
  }
  if (event.extendedProps.event_type === EventType.VISIT) {
    return userStore.canGetVisitDetail;
  }
  return false;
};

export const handleRedirectByEventType = async (
  event: UserEventSchema,
  router: ReturnType<typeof useRouter>
): Promise<void> => {
  const userStore = useUser();

  if (event.extendedProps?.event_type === EventType.GROUP_VISIT) {
    await router.push({
      name: 'group-visit-detail',
      params: {
        groupVisitId: event?.id,
      },
    });
    return;
  }
  if (
    event.extendedProps?.user_id === userStore.user?.id ||
    userStore.canViewColleagueVisit
  ) {
    await router.push({
      name: 'visit-detail',
      params: {
        patientId: event.extendedProps?.patient_id,
        visitId: event.id,
      },
    });
  }
};

export const isEditableByTypeAndPermission = (
  event: UserEventSchema
): boolean => {
  const userStore = useUser();

  if (event.extendedProps.event_type === EventType.GROUP_VISIT) {
    return false; // group visit is not editable from calendar
  }
  if (event.extendedProps.event_type === EventType.VISIT) {
    return userStore.canUpdateVisit;
  }
  return false;
};

export const getPermittedCalendarRoutes = (): CalendarRoute[] => {
  const userStore = useUser();
  const availableRoutes: CalendarRoute[] = [];

  if (userStore.canViewCalendarUsers) {
    availableRoutes.push(CalendarRoute.Users);
  }
  if (userStore.canViewCalendarRooms) {
    availableRoutes.push(CalendarRoute.Rooms);
  }
  if (userStore.canViewCalendarPlanning) {
    availableRoutes.push(CalendarRoute.Planning);
  }
  if (userStore.canViewCalendarRoomsPlanning) {
    availableRoutes.push(CalendarRoute.RoomPlanning);
  }

  return availableRoutes;
};

export enum RoomPlanningCalendarViewType {
  Day = 'resourceRoomPlanningTimelineDay',
}

export const initRoomCalendarView = ResourceCalendarViewType.WorkingDays;
export const initMainCalendarView = CalendarViewType.WorkingDays;
export const initPlanningCalendarView = PlanningCalendarViewType.Week;

export const initMainCalendarViewMobile = CalendarViewType.Day;
export const initRoomCalendarViewMobile = ResourceCalendarViewType.Day;
export const initPlanningCalendarViewMobile = PlanningCalendarViewType.Day;

export interface CalendarViewOption {
  label: string;
  type: CalendarViewType | ResourceCalendarViewType | PlanningCalendarViewType;
}

export const roomCalendarViewOptions: CalendarViewOption[] = [
  {
    label: $gettext('Dny'),
    type: ResourceCalendarViewType.Day,
  },
  {
    label: $gettext('Po třech dnech'),
    type: ResourceCalendarViewType.ThreeDays,
  },
  {
    label: $gettext('Pracovní dny'),
    type: ResourceCalendarViewType.WorkingDays,
  },
  {
    label: $gettext('Týdny'),
    type: ResourceCalendarViewType.Week,
  },
];

export const mainCalendarOptions: CalendarViewOption[] = [
  {
    label: $gettext('Dny'),
    type: CalendarViewType.Day,
  },
  {
    label: $gettext('Po třech dnech'),
    type: CalendarViewType.ThreeDays,
  },
  {
    label: $gettext('Pracovní dny'),
    type: CalendarViewType.WorkingDays,
  },
  {
    label: $gettext('Týdny'),
    type: CalendarViewType.Week,
  },
  {
    label: $gettext('Měsíce'),
    type: CalendarViewType.Month,
  },
];

export const planningCalendarOptions: CalendarViewOption[] = [
  {
    label: $gettext('Dny'),
    type: PlanningCalendarViewType.Day,
  },
  {
    label: $gettext('Týdny'),
    type: PlanningCalendarViewType.Week,
  },
];

export const handleResourceLabel = (arg: ColCellContentArg): string => {
  if (arg.groupValue) {
    switch (arg.groupValue) {
      case 'room':
        return $gettext('Místnost');
      case 'user':
        return $gettext('Fyzioterapeut');
    }
  }
  return '';
};

export const handleHeaderLabel = (arg: ColHeaderContentArg): string => {
  if (arg.view.type === 'resourceTimelineDay') {
    const userStore = useUser();

    return arg.view.currentStart
      ? arg.view.currentStart.toLocaleDateString(
          userStore.getPreferredLanguage,
          {
            weekday: 'short',
            day: 'numeric',
            month: 'numeric',
          }
        )
      : '';
  }
  return '';
};

export const setSlotDuration = (setting: boolean): string => {
  if (setting) {
    return '00:15:00';
  }
  return '00:30:00';
};

export const setSlotMinWidth = (setting: boolean): number => {
  if (setting) {
    return 15;
  }
  return 20;
};

export const routeIconMap = new Map([
  [CalendarRoute.Users, 'fa-solid fa-user'],
  [CalendarRoute.Rooms, 'fa-solid fa-house'],
  [CalendarRoute.Planning, 'fa-solid fa-calendar-alt'],
  [CalendarRoute.RoomPlanning, 'fa-solid fa-table-cells-large'],
]);

export const calendarViewOptionsMap = new Map([
  [CalendarRoute.Users, mainCalendarOptions],
  [CalendarRoute.Rooms, roomCalendarViewOptions],
  [CalendarRoute.Planning, planningCalendarOptions],
]);

export const calendarViewInitMap = new Map<CalendarRoute, AllCalendarViewType>([
  [CalendarRoute.Users, initMainCalendarView],
  [CalendarRoute.Rooms, initRoomCalendarView],
  [CalendarRoute.Planning, initPlanningCalendarView],
]);

export const calendarMobileViewInitMap = new Map<
  CalendarRoute,
  AllCalendarViewType
>([
  [CalendarRoute.Users, initMainCalendarViewMobile],
  [CalendarRoute.Rooms, initRoomCalendarViewMobile],
  [CalendarRoute.Planning, initPlanningCalendarViewMobile],
]);

export const resourceLabelMap = new Map([
  [CalendarRoute.Users, $gettext('Fyzioterapeuti')],
  [CalendarRoute.Rooms, $gettext('Místnosti')],
]);
