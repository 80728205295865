import { createGettext } from 'vue3-gettext';
import { Cookies } from 'quasar';
import fyzionTranslations from '../language/fyzion/translations.json';
import cortexTranslations from '../language/cortex/translations.json';
import { ref } from 'vue';

const translations =
  process.env.THEME === 'cortex' ? cortexTranslations : fyzionTranslations;

const storedLanguage = Cookies.get('preferredLanguage');
const detectedLanguage = storedLanguage || navigator.language.slice(0, 2);

if (!storedLanguage) {
  Cookies.set('preferredLanguage', detectedLanguage, {
    path: '/',
    expires: 730,
  });
}

export const currentLanguage = ref(detectedLanguage);

const gettext = createGettext({
  availableLanguages: {
    en: 'English',
    cs: 'Česky',
    sk: 'Slovensky',
    pl: 'Polski',
  },
  defaultLanguage: currentLanguage.value,
  translations,
  silent: true,
});

export const setLanguage = (newLang: string): void => {
  Cookies.set('preferredLanguage', newLang, { path: '/', expires: 730 });
  currentLanguage.value = newLang;
};

export default gettext;
