/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckEmailRequest } from '../models/CheckEmailRequest';
import type { CheckEmailResponse } from '../models/CheckEmailResponse';
import type { CustomerUserGuideDataSchema } from '../models/CustomerUserGuideDataSchema';
import type { Me } from '../models/Me';
import type { PasswordChange } from '../models/PasswordChange';
import type { RecurringUpdateStrategyChoices } from '../models/RecurringUpdateStrategyChoices';
import type { TimeOffCreateSchema } from '../models/TimeOffCreateSchema';
import type { TimeOffDetailSchema } from '../models/TimeOffDetailSchema';
import type { TimeOffListSchema } from '../models/TimeOffListSchema';
import type { TimeOffPatchSchema } from '../models/TimeOffPatchSchema';
import type { TimeOffUpdateSchema } from '../models/TimeOffUpdateSchema';
import type { UpdateMe } from '../models/UpdateMe';
import type { WorkingHoursCreateSchema } from '../models/WorkingHoursCreateSchema';
import type { WorkingHoursDetailSchema } from '../models/WorkingHoursDetailSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersApi {
    /**
     * Check Email
     * @returns CheckEmailResponse OK
     * @throws ApiError
     */
    public static usersCheckEmailPost({
        requestBody,
    }: {
        requestBody: CheckEmailRequest,
    }): CancelablePromise<CheckEmailResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/check-email/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create Time Off
     * Create time off.<br>Required Permission: 'create_my_time_off'
     * @returns TimeOffDetailSchema Created
     * @throws ApiError
     */
    public static usersCreateTimeOffPost({
        requestBody,
    }: {
        requestBody: TimeOffCreateSchema,
    }): CancelablePromise<TimeOffDetailSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/time-off/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Time Offs
     * Get time off list.<br>Required Permission: 'get_my_time_off'
     * @returns TimeOffListSchema OK
     * @throws ApiError
     */
    public static usersGetTimeOffsGet({
        userId,
        startDate,
        endDate,
        orderBy,
    }: {
        userId?: (string | null),
        startDate?: (string | null),
        endDate?: (string | null),
        orderBy?: (string | null),
    }): CancelablePromise<Array<TimeOffListSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/time-off/',
            query: {
                'user_id': userId,
                'start_date': startDate,
                'end_date': endDate,
                'order_by': orderBy,
            },
        });
    }
    /**
     * Create Working Hours
     * Create working hours.<br>Required Permission: 'create_my_working_hours'
     * @returns WorkingHoursDetailSchema OK
     * @throws ApiError
     */
    public static usersCreateWorkingHoursPost({
        requestBody,
    }: {
        requestBody: WorkingHoursCreateSchema,
    }): CancelablePromise<WorkingHoursDetailSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/working-hours/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Working Hours
     * Get working hours.<br>Required Permission: 'get_my_working_hours'
     * @returns WorkingHoursDetailSchema OK
     * @throws ApiError
     */
    public static usersGetWorkingHoursGet(): CancelablePromise<WorkingHoursDetailSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/working-hours/',
        });
    }
    /**
     * Update Working Hours
     * Update working hours.<br>Required Permission: 'update_my_working_hours'
     * @returns WorkingHoursDetailSchema OK
     * @throws ApiError
     */
    public static usersUpdateWorkingHoursPut({
        requestBody,
    }: {
        requestBody: WorkingHoursCreateSchema,
    }): CancelablePromise<WorkingHoursDetailSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/users/working-hours/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Time Off
     * Delete time off.<br>Required Permission: 'delete_my_time_off'
     * @returns void
     * @throws ApiError
     */
    public static usersDeleteTimeOffDelete({
        timeOffId,
        recurringUpdateStrategy,
    }: {
        timeOffId: string,
        recurringUpdateStrategy?: (RecurringUpdateStrategyChoices | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/users/time-off/{time_off_id}/',
            path: {
                'time_off_id': timeOffId,
            },
            query: {
                'recurring_update_strategy': recurringUpdateStrategy,
            },
        });
    }
    /**
     * Get Time Off
     * Get time off.<br>Required Permission: 'get_my_time_off'
     * @returns TimeOffDetailSchema OK
     * @throws ApiError
     */
    public static usersGetTimeOffGet({
        timeOffId,
    }: {
        timeOffId: string,
    }): CancelablePromise<TimeOffDetailSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/time-off/{time_off_id}/',
            path: {
                'time_off_id': timeOffId,
            },
        });
    }
    /**
     * Patch Time Off
     * Patch time off. <br>Required Permission: update_time_off
     * @returns TimeOffDetailSchema OK
     * @throws ApiError
     */
    public static usersPatchTimeOffPatch({
        timeOffId,
        requestBody,
    }: {
        timeOffId: string,
        requestBody: TimeOffPatchSchema,
    }): CancelablePromise<TimeOffDetailSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/users/time-off/{time_off_id}/',
            path: {
                'time_off_id': timeOffId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Time Off
     * Update time off.<br>Required Permission: 'update_my_time_off'
     * @returns TimeOffDetailSchema OK
     * @throws ApiError
     */
    public static usersUpdateTimeOffPut({
        timeOffId,
        requestBody,
    }: {
        timeOffId: string,
        requestBody: TimeOffUpdateSchema,
    }): CancelablePromise<TimeOffDetailSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/users/time-off/{time_off_id}/',
            path: {
                'time_off_id': timeOffId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Guide Data
     * Get guide data. <br>Required permission: get_guide_data
     * @returns CustomerUserGuideDataSchema OK
     * @throws ApiError
     */
    public static usersGetGuideDataGet(): CancelablePromise<CustomerUserGuideDataSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/guide-data/',
        });
    }
    /**
     * Update Guide Data
     * Update guide data. <br>Required permission: update_guide_data
     * @returns CustomerUserGuideDataSchema OK
     * @throws ApiError
     */
    public static usersUpdateGuideDataPut({
        requestBody,
    }: {
        requestBody: CustomerUserGuideDataSchema,
    }): CancelablePromise<CustomerUserGuideDataSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/users/me/guide-data/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Me
     * Get me detail. <br>Required permission: get_me
     * @returns Me OK
     * @throws ApiError
     */
    public static usersGetMeGet(): CancelablePromise<Me> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/',
        });
    }
    /**
     * Update Me
     * Update me. <br>Required Permission: update_me
     * @returns Me OK
     * @throws ApiError
     */
    public static usersUpdateMePut({
        requestBody,
    }: {
        requestBody: UpdateMe,
    }): CancelablePromise<Me> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/users/me/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Password Change
     * Change password. <br>Required Permission: password_change
     * @returns any OK
     * @throws ApiError
     */
    public static usersPasswordChangePost({
        requestBody,
    }: {
        requestBody: PasswordChange,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/password-change/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * Patch Time Off Deprecated
     * Patch time off. <br>Required Permission: update_time_off
     * @returns TimeOffDetailSchema OK
     * @throws ApiError
     */
    public static usersPatchTimeOffDeprecatedPatch({
        timeOffId,
        requestBody,
    }: {
        timeOffId: string,
        requestBody: TimeOffPatchSchema,
    }): CancelablePromise<TimeOffDetailSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/users/{time_off_id}/',
            path: {
                'time_off_id': timeOffId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Profile Picture
     * Upload my profile picture. <br>Required Permission: update_me
     * @returns Me OK
     * @throws ApiError
     */
    public static usersUpdateProfilePicturePost({
        formData,
    }: {
        formData: {
            image: Blob;
        },
    }): CancelablePromise<Me> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/profile-picture/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}
